$(document).ready(function() {

    isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;

    }

    function checkMobile() {
        if (isMobile) {
            $("body").addClass("is--mobile");
            $("body").removeClass("is--desktop");
        } else {
            $("body").removeClass("is--mobile");
            $("body").addClass("is--desktop");
        }
    }
    checkMobile();


    //accordion
    function accordion(accordion) {
        accordion.find("button").click(function() {
            if ($(this).hasClass("active")) {
                accordion.find(".accordion-panel").slideUp().removeClass("active");
                $(this).removeClass("active");
            } else {
                accordion.find(".accordion-panel").slideDown().addClass("active");
                $(this).addClass("active");
            }
        })
    }
    $(".accordion").each(function() {
        accordion($(this));
    }) //accordion




    window.addEventListener("scroll", function(e) {

        if ($(".header").length) {

            var navbar = $(".header");
            var sticky = navbar[0].offsetTop;
            var st;
            var lastScrollTop = 0;

            function addFixedMenu() {

                if (window.pageYOffset >= navbar.height() + 300) {
                    navbar.addClass("fixed-menu");
                    $("body").addClass("menu-is-fixed");
                    $(".card__nav").addClass("sticky");
                    if ($("#form-advanced").hasClass("open")) {
                        closeSearchAdvance();
                    }

                } else {
                    navbar.removeClass("fixed-menu");
                    $("body").removeClass("menu-is-fixed");
                    $(".card__nav").removeClass("sticky");
                }
            }
            if ($("body").height() > ($(window).height() + 400)) {

                st = $(this).scrollTop();
                $("#menu-shadow").click();
                addFixedMenu();
                lastScrollTop = st;

            };
        }
    });


    if (window.ontouchstart !== undefined) {
        $("body").addClass("menu-mobile");
        $(".navbar-toggler").click(function(e) {
            e.preventDefault();
            e.stopPropagation();
        })
        $(".menu-1 .menu__item--has-children").click(function() {
            $(this).siblings(".submenu-2").slideToggle();;
        })

    } else {
        $(".menu-1>li").hover(function() {
            $(this).find(".submenu-2").css("display", "block");
        }, function() {
            $(this).find(".submenu-2").css("display", "none");
        })
    }

    //login box

    function loginBoxClose(btn) {
        if (btn.hasClass("active")) {
            $(".header__login-box").slideUp();
            btn.removeClass("active");
            $("#box-close-login").remove();
            $(".header__login-box").removeClass("z-index-100");
        }
    }
    $(".header__login-btn").click(function() {
        if ($("#form-advanced").hasClass("open")) {
            closeSearchAdvance();
        }
        if ($(this).hasClass("active")) {
            loginBoxClose($(this));

        } else {
            cartBoxClose($(".header__cart-btn"));
            if ($(".nav-toggle").hasClass("open")) {
                $(".nav-toggle").click();
            }
            $(".header__login-box").slideDown();
            $(this).addClass("active");
            $("body").prepend("<span class='box-close' id='box-close-login'></span>");
            $(".header__login-box").addClass("z-index-100");
        }
    })
    $("body").on("click", "#box-close-login", function() {
        loginBoxClose($(".header__login-btn"));
    })
    //cart box
    function cartBoxClose(btn) {

        $(".header__cart-box").removeClass("open");
        btn.removeClass("active");
        $("#box-close-cart").remove();

        $("body").removeClass("overflow--hidden");
        $(".header__cart-box .info--green").css("display", "none");
    }

    $(".header__cart-btn").click(function(e) {
        e.preventDefault();
        if ($("#form-advanced").hasClass("open")) {
            closeSearchAdvance();
        }
        if ($(this).hasClass("active")) {
            cartBoxClose($(this));
        } else {
            loginBoxClose($(".header__login-btn"));
            $(".header__cart-box").addClass("open");
            $(this).addClass("active");
            $("body").addClass("overflow--hidden").prepend("<span class='open-menu-shadow' id='box-close-cart'></span>");
        }

        if ($(".nav-toggle").hasClass("open")) {
            $(".nav-toggle").click();
        }
    })

    $("body").on("click", "#box-close-cart, .btn-close-cart, .btn-close-cart2 ", function() {
        cartBoxClose($(".header__cart-btn"));
    })


    $("body").on("click", ".nav-toggle", function(e) {
        e.preventDefault();
        if ($("#form-advanced").hasClass("open")) {
            closeSearchAdvance();
        }
        if ($(this).hasClass("open")) {
            $(".nav").removeClass("open");
            $(this).removeClass("open");
            $(".nav .active").removeClass();
            $("#menu-shadow").remove();
            $("body").removeClass("body-fixed");
            $(".nav__wrapper").removeClass("isopen");

        } else {
            $(".nav").addClass("open");
            $(".nav").slideDown();
            $(this).addClass("open");
            $("body").prepend("<div class='open-menu-shadow' id='menu-shadow'></div>");
            $("body").addClass("body-fixed");
            loginBoxClose($(".header__login-btn"));
            $(".nav__wrapper").addClass("isopen");
        }

    })

    $(".menu-2>li>a").click(function(e) {
        e.preventDefault();
        $(this).siblings(".menu-3").toggleClass("open");
        if ($(this).siblings(".menu-3").hasClass("open")) {
            $(this).siblings(".menu-3").slideDown();
        } else {
            $(this).siblings(".menu-3").slideUp();
        }
    })


    function closeSearchAdvance() {

        if ($("#form-advanced").hasClass("open")) {
            $("#form-advanced").slideUp();
            $("#form-advanced").removeClass("open");
            $("#box-close-search-advance").remove();
            $("body").removeClass("overflow--hidden");
        } else {
            $("#form-advanced").addClass("open");
            $("#form-advanced").slideDown();
            $("body").addClass("overflow--hidden").prepend("<span class='open-menu-shadow' id='box-close-search-advance'></span>");
        }
    }

    $("body").on("click", ".btn--advanced, #box-close-search-advance", function(e) {
        e.preventDefault();
        closeSearchAdvance();
    })


    $(".form-advanced-close").click(function(e) {
        e.preventDefault();
        closeSearchAdvance();
    })


});



//mapa z regionami
function clickOnRegionName(btn) {

    let region = btn.data("map");
    $("#map-italy .active").removeClass("active");
    btn.addClass("active");
    $("#" + region).addClass("active");
    $(".map__des [data-map=" + region + "]").addClass("active");
}

function clickOnRegioMap(btn, event = "no") {
    let region = btn.attr("id");
    region = region.replace("#", "");
    //if ($(".map__des [data-map=" + region + "]").length == 0) return;
    $("#map-italy .active").removeClass("active");
    btn.addClass("active");
    console.log($(".map__des [data-map=" + region + "]").length);
    if ($(".map__des [data-map=" + region + "]").length > 0) { $(".map__des [data-map=" + region + "]").addClass("active"); }
    $(".map__list a[data-map=" + region + "]").addClass("active");

    if (event == "click") {
        if ($(".map__list a[data-map=" + region + "]").length) { window.location.href = $(".map__list a[data-map=" + region + "]").attr("href"); }
    }
}


if (window.ontouchstart !== undefined) {
    $(".map__list a").click(function() {
        clickOnRegionName($(this));
    })
    $("#map-italy .map__item").click(function() {
        clickOnRegioMap($(this), "click");
    })

} else {
    $(".map__list a").hover(function(e) {
        e.preventDefault();
        clickOnRegionName($(this));
    })
    $("#map-italy .map__item").hover(function() {
        clickOnRegioMap($(this));
        console.log("desktop");
    }, function() {
        if ($(".active-bg").length > 0) {
            $("#map-italy .active").removeClass("active");
            $(".active-bg").addClass("active");
            $(".map__list a[data-map=" + $(".active-bg").attr("id") + "]").addClass("active");

        }
    })
    $("#map-italy .map__item").click(function() {
        clickOnRegioMap($(this), "click");
    })
}

$(".link__parent").click(function() {
    window.location.href = $(this).find("a").attr("href");
})

$("body").on("click", ".product__link figure", function() {
    window.location.href = $(this).parents(".product__link").find("a").attr("href");
})



//stałe zakupy

function ulubioneActive() {

    $.get('/a/', {
            a: "ulubioneIle",
        },
        function(data) {

            if (data == 0) {
                $(".header__shopping img").attr("src", "/szablony/public/img/stalezakupy.svg");
                $(".ulubioneIle").html("");
                if ($("#favourite-amount").length) {
                    $("#favourite-amount").html("");
                    $("#favourite-info").css("display", "block");
                }

            } else {
                $(".header__shopping img").attr("src", "/szablony/public/img/stalezakupy-active.svg");
                $(".ulubioneIle").html(data);
                if ($("#favourite-amount").length) {
                    $("#favourite-amount").css("display", "inline-block").html("(" + data + ")");
                    $("#favourite-info").css("display", "none");
                }
            }

        }
    );
}

$(document).on('click', '.constant-cart', function(e) {
    let $this = $(this);
    e.preventDefault();
    let id = 0;
    if ($this.parents(".product__item").length) {
        id = $this.parents(".product__item").data("id");
    } else {
        if ($this.parents(".card").length) { id = $this.parents(".card").data("id"); } else {
            id = $this.parents(".product-list__item").data("id");
        }
    }

    if ($this.hasClass("is--active")) {


        $.post('/a/', {
                a: "lista-zakupowa-usun",
                produkt: id,
            },
            function(data) {
                if ($this.parents(".isfavourite").length) {
                    $this.parents(".isfavourite").remove();
                }
                $this.removeClass("is--active");
                ulubioneActive();

            }
        );
    } else {


        $.post('/a/', {
                a: "lista-zakupowa-dodaj",
                produkt: id,
            },
            function(data) {
                $this.addClass("is--active");
                ulubioneActive();
            }
        )
    }


})

//stale zakupy - END




//Formatownaie numerów
let number_format = function(number, decimals, dec_point, thousands_sep) {
    console.log(number);
    if (number.isNumeric) {
        number = number.toFixed(decimals);
        var nstr = number.toString();
    } else {
        number = parseFloat(number).toFixed(decimals);
        var nstr = number.toString();
    }
    console.log(number);
    nstr += '';
    let x = nstr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? dec_point + x[1] : '';
    var rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + thousands_sep + '$2');

    return x1 + x2;
}

function changePrice(price) {
    if (price || price == 0) {
        return number_format(price, 2, ',', ' ')
    }
}
//Formatownaie numerów -END



//dodawanie produktów do lewego koszyka
let sumAllProductInRightCart = 0;

function podsumowanie(data) {
    let podsumowanie = data;
    $(".header__cart-nr").html(podsumowanie.ilosc);
    $("#header-cart-sum").html(changePrice(podsumowanie.wartosc) + " zł");
    $("#header-cart-del").html(changePrice(podsumowanie.dostawaBrutto) + " zł");
    sumAllProductInRightCart = podsumowanie.wartosc;
}

function createCartHeaderList() {

    $.get('/a/', {
            a: "koszyk",
        },
        function(data) {
            if (Array.isArray(data)) {
                $(".header__cart-sum").show();
                $(".btn--go-cart").show();
                $(".cart--empty").hide();
                let products = data;
                $(".header__cart-wrapper").html("");
                $.each(products, function(i, product) {

                    $(".header__cart-wrapper").append(
                        '<li data-id="' + product.produkt + '"><button class="btn--close btn--close--small"></button><a href="' + product.url + '" class="header__cart-item"><figure><img src="' + product.produkt_zdjecie + '" alt=""></figure><div class="header__cart-item-box"><h3>' + product.produkt_nazwa + '</h3><p><span>' + product.ilosc + ' szt.</span> <strong>' + changePrice((product.produkt_cena).toFixed(2).toString().replace(".", ",")) + ' zł</strong></p></div></a></li>'
                    )
                })
            } else {
                $(".header__cart-sum").hide();
                $(".btn--go-cart").hide();
                if ($(".cart--empty").length) {
                    $(".cart--empty").show()
                } else {
                    $(".header__cart-wrapper").before("<div class='text-center cart--empty m-t-30'> <img src='/szablony/public/img/koszyk.svg' alt=''><div class='login-in'><h3 class='m-t-30 bold'>Nie widzisz swoich produktów w koszyku?</h3><p class='m-b-20'>Masz w koszyku na swoim koncie dodane produkty?<br>Zaloguj się aby je zobaczyć.</p><div class='m-b-20'><a href='/logowanie/' class='btn' >Logowanie</a></div></div></div>");
                }
            }
        })

    $.get('/a/', {
            a: "koszyk-podsumowanie",
        },
        function(data) {
            podsumowanie(data);

            $.get('/a/', {
                a: "darmowa-dostawa",
            }, function(data) {
                if (data.darmowaDostawa) {

                    if (data.darmowaDostawa < sumAllProductInRightCart) { $(".cart-free-shipping").hide() } else {
                        $(".cart-free-shipping").show();
                        $("#cart-free-shipping__price").html(changePrice(data.darmowaDostawa - sumAllProductInRightCart) + ' zł')
                    }
                }
            });


        })


}


$("body").on('click', '.product__item .btn--form button, .card .btn--form button, .product-list__item .btn--form button', function(e) {
    e.preventDefault();
    let id = "";
    if ($(this).parents(".product__item").length) {
        id = $(this).parents(".product__item").data("id");
    } else {
        if ($(this).parents(".card").length) { id = $(this).parents(".card").data("id"); } else {
            id = $(this).parents(".product-list__item").data("id");
        }
    }
    let number = $(this).siblings("input").val();

    $(".header__cart-box .info--green").css("display", "block");
    $.post('/a/', {
            a: "koszyk-dodaj",
            produkt: id,
            ilosc: number,
        },
        function(data) {
            if (data == "ok") {
                $(".header__cart-btn").removeClass("beforeFirstClick");
                $(".header__cart-btn").click();
                createCartHeaderList();
            }

        })

})


$("body").on("click", ".header__cart-wrapper .btn--close", function() {

    let id = $(this).parents("li").data("id");
    let $this = $(this);
    $.post('/a/', {
        a: "koszyk-usun",
        produkt: id,
    }, function() {
        $.get('/a/', {
                a: "koszyk-podsumowanie",
            },
            function(data) {
                podsumowanie(data);
                $.get('/a/', {
                    a: "darmowa-dostawa",
                }, function(data) {
                    if (data.darmowaDostawa) {
                        if (data.darmowaDostawa < sumAllProductInRightCart) { $(".cart-free-shipping").hide() } else {
                            $(".cart-free-shipping").show();
                            $("#cart-free-shipping__price").html(changePrice(data.darmowaDostawa - sumAllProductInRightCart) + ' zł')
                        }
                    }
                });


            })
        $this.parents("li").remove();


    })
})

$(".header__cart-btn").click(function() {
    if ($(this).hasClass("beforeFirstClick")) {
        createCartHeaderList();
        $(this).removeClass("beforeFirstClick");
    }
})



//zaznaczanie slajdera wyglad-smak-aromat
if (window.ontouchstart == undefined) {
    $(".inputs-group label").hover(function(e) {
        if ($(this).hasClass("nopress")) {} else {
            $(this).parents(".inputs-group").find("label").removeClass("label-checked-hover");
            $(this).addClass("label-checked-hover");
            $(this).prevAll("label").addClass("label-checked-hover");
        }
    }, function(e) {
        if (!$(this).hasClass("nopress")) { $(this).parents(".inputs-group").find("label").removeClass("label-checked-hover"); }
    })
}


$("body").on("click", ".inputs-group label", function(e) {

    let aktywacja = $(this).parents("fieldset").find(".inputs-group__nav input");

    if ($(this).hasClass("nopress")) {
        if (!aktywacja.prop("checked")) {
            aktywacja.prop("checked", "checked").change();
        }
    }
    $(this).parents(".inputs-group").find("input[checked]").removeAttr("checked");
    $(this).prev("input").prop("checked", "checked").change();
    $(this).parents(".inputs-group").find("label").removeClass("label-checked");
    $(this).addClass("label-checked");
    $(this).prevAll("label").addClass("label-checked");

})


function aktywujAromat(input) {

    if (input.prop("checked")) {
        $("#aktywujAromat-items input").removeClass("noserialize").removeAttr("disabled");
        $("#aktywujAromat-items label").removeClass("nopress");
        $("#aktywujAromat-items").parents(".inputs-group__wrapper").removeClass("noactive");
    } else {
        $("#aktywujAromat-items input").addClass("noserialize").attr("disabled", "disabled");
        $("#aktywujAromat-items label").addClass("nopress");
        $("#aktywujAromat-items").parents(".inputs-group__wrapper").addClass("noactive");
    }
}


$("#aktywujAromat").on("change", function() {
    aktywujAromat($(this));
})

function aktywujSmak(input) {

    if (input.prop("checked")) {
        $("#aktywujSmak-items input").removeClass("noserialize").removeAttr("disabled");
        $("#aktywujSmak-items label").removeClass("nopress");
        $("#aktywujSmak-items").find(".group__wrapper").removeClass("noactive");
    } else {
        $("#aktywujSmak-items input").addClass("noserialize").attr("disabled", "disabled");
        $("#aktywujSmak-items label").addClass("nopress");
        $("#aktywujSmak-items").find(".group__wrapper").addClass("noactive");
    }
}

$("#aktywujSmak").on("change", function() {
    aktywujSmak($(this));
})